import { applyJobApi, getInviteApplyDetailsApi, getS3SignUrlForApplyJob } from "@/Services/apis/job.api";
import { addNewSkillsApi } from "@/Services/apis/profile.api";
import { getLocalstorageData } from "@/Utils/CommonFuncs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  resumeApplyJob: {},
  coverLetterApplyJob: {},
  inviteDetails: {},
};
/* eslint-enable @typescript-eslint/no-explicit-any */

//This function is for add new skills during aplly job
export const addNewSkills = createAsyncThunk(
  "addNewSkills",
  async ({ valueForAddSkillsApi }: any, { rejectWithValue }) => {
    //eslint-disable-line
    try {
      const addNewSkillsData: any = await addNewSkillsApi(valueForAddSkillsApi); //eslint-disable-line
      if (addNewSkillsData?.code !== 200) return rejectWithValue(addNewSkillsData);
      if (addNewSkillsData?.code === 200) {
        //eslint-disable-line
      }
      return addNewSkillsData;
    } catch (error: any) {
      //eslint-disable-line
      return rejectWithValue(error.response.data);
    }
  }
);

//This function is of apply job
export const applyJob = createAsyncThunk("applyJob", async ({ value }: any, { rejectWithValue }) => {
  //eslint-disable-line
  try {
    const applyJobData: any = await applyJobApi(value); //eslint-disable-line
    if (applyJobData?.code !== 200) return rejectWithValue(applyJobData);
    if (applyJobData?.code === 200) {
      toast.success(applyJobData?.message);
      value?.router.replace("/jobs/my-jobs?value=2");
      return applyJobData;
    }
  } catch (error: any) {
    //eslint-disable-line
    return rejectWithValue(error.response.data);
  }
});
//This function is for the file upload
/* eslint-disable @typescript-eslint/no-explicit-any */
export const fileUploadApplyJob = createAsyncThunk("fileUploadApplyJob", async (values: any, { rejectWithValue }) => {
  const file = values.blobFile;
  values.role = getLocalstorageData("currentDashboardRole");
  try {
    const data: any = await getS3SignUrlForApplyJob(values);

    if (data?.code !== 200) return rejectWithValue(data);
    if (data?.code === 200) {
      if (values?.type === "resume") {
        values?.dispatch(
          setResumeApplyJob({
            file,
            type: values?.dataFiles?.ContentType,
            name: values?.dataFiles?.fileName,
            link: data?.uploadImageUrl,
          })
        );
        values?.applyJobFormik?.setFieldValue("resume", "done");
      }
      if (values?.type === "coverLetter") {
        values?.dispatch(
          setCoverLetterApplyJob({
            file,
            type: values?.dataFiles?.ContentType,
            name: values?.dataFiles?.fileName,
            link: data?.uploadImageUrl,
          })
        );
        values?.applyJobFormik?.setFieldValue("coverLetter", "done");
      }
    }
    return { data };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
/* eslint-enable @typescript-eslint/no-explicit-any */
export const inviteApplyJobDetails = createAsyncThunk(
  "inviteApplyJobDetails",
  async (values: any, { rejectWithValue }) => {
    //eslint-disable-line
    try {
      const inviteApplyJobDetailsData: any = await getInviteApplyDetailsApi(values); //eslint-disable-line
      if (inviteApplyJobDetailsData?.code !== 200) return rejectWithValue(inviteApplyJobDetailsData);

      return inviteApplyJobDetailsData;
    } catch (error: any) {
      //eslint-disable-line
      return rejectWithValue(error.response.data);
    }
  }
);
export const jobApplySlice = createSlice({
  name: "jobApplySlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setResumeApplyJob: (state, action) => {
      state.resumeApplyJob = action.payload;
    },
    setCoverLetterApplyJob: (state, action) => {
      state.coverLetterApplyJob = action.payload;
    },
    setinviteApplydetails: (state, action) => {
      state.inviteDetails = action.payload;
    },
  },
  /* eslint-disable @typescript-eslint/no-explicit-any */

  extraReducers: (builder) => {
    builder
      .addCase(inviteApplyJobDetails.pending, (_state) => {})
      .addCase(inviteApplyJobDetails.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        state.inviteDetails = payload?.candidateDetails;
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(inviteApplyJobDetails.rejected, (_state) => {});
  },
  /* eslint-enable @typescript-eslint/no-explicit-any */
});

export const { setResumeApplyJob, setCoverLetterApplyJob, setinviteApplydetails } = jobApplySlice.actions;

export default jobApplySlice.reducer;
