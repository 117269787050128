import React from "react";
import { Box, Typography } from "@mui/material";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";

const NoInternet: React.FC = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="99vw"
      position="sticky"
      top="0"
      left="0"
      textAlign="center"
      bgcolor="#f9f9f9"
      padding={3}
      zIndex={10}
    >
      <SentimentDissatisfiedIcon color="disabled" sx={{ fontSize: 80, marginBottom: 2 }} />
      <Typography variant="h5" gutterBottom>
        No Internet Connection
      </Typography>
      <Typography variant="body1" color="textSecondary" marginBottom={3}>
        It seems there is nothing to display here. Check back later or try refreshing the page.
      </Typography>
      {/* <Button variant="contained" color="primary" onClick={handleAction}>
        Take Action
      </Button> */}
    </Box>
  );
};

export default NoInternet;
