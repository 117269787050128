import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type

/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  chatAuthenticatedData: {},
};

export const chatAuthSlice = createSlice({
  name: "chatAuthSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setChatAuthenticatedData: (state, action) => {
      state.chatAuthenticatedData = action.payload;
    },
  },

  extraReducers: () => {},
});

export const { setChatAuthenticatedData } = chatAuthSlice.actions;

export default chatAuthSlice.reducer;
