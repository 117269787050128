// Define the initial state using that type

import {
  getScheduleIntervieListApi,
  interviewRecordStartApi,
  postScheduleInterviewApi,
  startInterviewApi,
  stopInterviewSessionApi,
} from "@/Services/apis/scheduleInterviews/scheduleInterviews.api";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  scheduledDetails: {},
  scheduleList: [],
};
/* eslint-enable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-enable @typescript-eslint/no-explicit-any */
//Verify referral code
/* eslint-disable @typescript-eslint/no-explicit-any */

//This is for Schedule/Reschedule the interview
export const scheduleInterview = createAsyncThunk("scheduleInterview", async (values: any, { rejectWithValue }) => {
  try {
    const scheduleInterviewData: any = await postScheduleInterviewApi(values);
    if (values?.resource === "schedule-interview") {
      if (scheduleInterviewData?.code !== 201) return rejectWithValue(scheduleInterviewData);
      if (scheduleInterviewData?.code === 201) {
        toast.success(scheduleInterviewData?.message);
      }
    } else {
      if (scheduleInterviewData?.code !== 200) return rejectWithValue(scheduleInterviewData);
      if (scheduleInterviewData?.code === 200) {
        toast.success(scheduleInterviewData?.message);
      }
    }

    return scheduleInterviewData;
  } catch (error: any) {
    // values?.router && values?.router?.replace("/feeds");

    return rejectWithValue(error.response.data);
  }
});

//Schedule Interview list
export const scheduleInterviewList = createAsyncThunk(
  "scheduleInterviewList",
  async (values: any, { rejectWithValue }) => {
    try {
      const scheduleInterviewData: any = await getScheduleIntervieListApi(values);
      if (scheduleInterviewData?.code !== 201) return rejectWithValue(scheduleInterviewData);
      if (scheduleInterviewData?.code === 201) {
        //eslint-disable-line
      }
      return scheduleInterviewData;
    } catch (error: any) {
      // values?.router && values?.router?.replace("/feeds");

      return rejectWithValue(error.response.data);
    }
  }
);

export const startInterviewSession = createAsyncThunk(
  "startInterviewSession",
  async (values: any, { rejectWithValue }) => {
    try {
      const scheduleInterviewData: any = await interviewRecordStartApi(values);
      if (scheduleInterviewData?.code !== 200) return rejectWithValue(scheduleInterviewData);

      return scheduleInterviewData;
    } catch (error: any) {
      // values?.router && values?.router?.replace("/feeds");

      return rejectWithValue(error.response.data);
    }
  }
);
export const stopInterviewSession = createAsyncThunk(
  "stopInterviewSession",
  async (values: any, { rejectWithValue }) => {
    try {
      const scheduleInterviewData: any = await stopInterviewSessionApi(values);
      if (scheduleInterviewData?.code !== 200) return rejectWithValue(scheduleInterviewData);

      return scheduleInterviewData;
    } catch (error: any) {
      // values?.router && values?.router?.replace("/feeds");

      return rejectWithValue(error.response.data);
    }
  }
);

export const startInterview = createAsyncThunk("startInterview", async ({ value }: any, { rejectWithValue }) => {
  try {
    const startInterviewData: any = await startInterviewApi(value);
    if (startInterviewData?.code !== 200) return rejectWithValue(startInterviewData);
    return startInterviewData;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const scheduleInterviewSlice = createSlice({
  name: "scheduleInterviewSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(scheduleInterview.pending, (_state) => {})
      .addCase(scheduleInterview.fulfilled, (_state) => {})
      .addCase(scheduleInterview.rejected, (_state) => {})
      .addCase(scheduleInterviewList.pending, (_state) => {})
      .addCase(scheduleInterviewList.fulfilled, (state, { payload }) => {
        state.scheduleList = [...payload.data];
      })
      .addCase(scheduleInterviewList.rejected, (_state) => {})
      .addCase(startInterviewSession.pending, (_state) => {})
      .addCase(startInterviewSession.fulfilled, (_state) => {})
      .addCase(startInterviewSession.rejected, (_state) => {})
      .addCase(stopInterviewSession.pending, (_state) => {})
      .addCase(stopInterviewSession.fulfilled, (_state) => {})
      .addCase(stopInterviewSession.rejected, (_state) => {});
  },
});

export const {
  //eslint-disable-line
} = scheduleInterviewSlice.actions;

export default scheduleInterviewSlice.reducer;
