import axiosMethods from "@/Services/https/https";

export const getUpcomingInterviewsListApi = async (values: any) => {
  //eslint-disable-line
  const getUpcomingInterviewsListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getUpcomingInterviewsListData;
};

export const getCompletedInterviewsListsApi = async (values: any) => {
  //eslint-disable-line
  const getCompletedInterviewsListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getCompletedInterviewsListData;
};

// When user delete their interview from completed interview lists
export const deleteCompletedInterviewApi = async (values: any) => {
  //eslint-disable-line
  const deleteCompletedInterview: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return deleteCompletedInterview;
};
