"use client";

import React, { useState } from "react";
import { LayoutComponnetType } from "@/Types/CommonComponents/UiComponentsType";
import CustomSideNav from "../Common/CustomSideNav/CustomSideNav";
import CustomTopNav from "../Common/CustomNavbar/CustomNavbar";
import { usePathname } from "next/navigation";
import { authRoutes, publicRoutes } from "@/router/staticRoutes.routes";

const TwoHalfLayoutUser = ({ children, headerText, route }: LayoutComponnetType) => {
  const [openHamburger, setOpenHamburger] = useState<boolean>(false);
  const [expanded, setExpanded] = React.useState<string | false>("");
  const path = usePathname();
  return (
    <div>
      <section className={!authRoutes?.includes(path) && !publicRoutes?.includes(path) ? "dashboard-wrap" : ""}>
        {!authRoutes?.includes(path) && !publicRoutes?.includes(path) && (
          <CustomSideNav
            openHamburger={openHamburger}
            setOpenHamburger={setOpenHamburger}
            setExpanded={setExpanded}
            expanded={expanded}
          />
        )}
        <div
          className={!authRoutes?.includes(path) && !publicRoutes?.includes(path) ? "dashboard-content" : ""}
          onClick={() => {
            setExpanded("");
          }}
        >
          {!authRoutes?.includes(path) && !publicRoutes?.includes(path) && (
            <CustomTopNav
              setOpenHamburger={setOpenHamburger}
              openHamburger={openHamburger}
              headerText={headerText}
              setExpanded={setExpanded}
              expanded={expanded}
              route={route}
            />
          )}
          <div
            onClick={() => {
              setExpanded("");
              setOpenHamburger(false);
            }}
          >
            {children}
          </div>
        </div>
      </section>
    </div>
  );
};

export default TwoHalfLayoutUser;
