import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type

/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  chatUsers: {},
  searchFriendsList: [],
};

export const chatUsersSlice = createSlice({
  name: "chatUsersSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setChatUsers: (_state, _action) => {},
    setSearchFriendsList: (state, action) => {
      if (action?.payload?.data?.pagination?.page > 1) {
        state.searchFriendsList = {
          code: action?.payload?.code,
          data: {
            list: [...state.searchFriendsList?.data?.list, ...action?.payload?.data?.list],
            pagination: action?.payload?.data?.pagination,
          },
        };
      } else {
        state.searchFriendsList = action?.payload;
      }
    },
  },

  extraReducers: () => {},
});

export const { setChatUsers, setSearchFriendsList } = chatUsersSlice.actions;

export default chatUsersSlice.reducer;
