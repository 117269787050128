import React, { useEffect } from "react";
import TwoHalfLayoutUser from "./TwoHalfLayoutUser";
import { LayoutComponnetType } from "@/Types/CommonComponents/UiComponentsType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/Redux/store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { getUser } from "@/Redux/userProfile.slice";
import { useRouter } from "next/navigation";

const UserLayout = ({ children, headerText, route }: LayoutComponnetType) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>();
  const { selectedDashboardData } = useSelector((state: RootState) => state.userProfile);
  const router = useRouter();
  useEffect(() => {
    const handleReload = () => {
      const navigationEntries = window.performance.getEntriesByType("navigation");
      if (
        navigationEntries.length > 0 &&
        navigationEntries[0] instanceof PerformanceNavigationTiming &&
        navigationEntries[0].type === "reload"
      ) {
        // Page reloaded
        dispatch(
          getUser({
            resource: "get-user",
            profileRole: selectedDashboardData?.profileType,
          })
        );
      }
    };
    if (selectedDashboardData?.profileType) {
      window.onload = handleReload;
    }
    return () => {
      window.onload = null; // Clean up event listener
    };
  }, [selectedDashboardData?.profileType]); // Empty dependency array ensures the effect runs only once

  useEffect(() => {
    router.prefetch("/feeds");
    router.prefetch("/profile/view");
    router.prefetch("/profile/edit");
    router.prefetch("/registration/become-company");
    router.prefetch("/registration/become-interviewer");
    router.prefetch("/settings/account-settings");
    router.prefetch("/settings/change-password");
    router.prefetch("/jobs/list");
    router.prefetch("/jobs/my-jobs");
    router.prefetch("/jobs/post");
  }, []);
  return (
    <TwoHalfLayoutUser headerText={headerText} route={route}>
      {children}
    </TwoHalfLayoutUser>
  );
};

export default UserLayout;
