import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  searchList: [],
};
export const invitePeople = createSlice({
  name: "invitePeople",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSearchList: (state, action) => {
      state.searchList = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder;
  },
});

export const { setSearchList } = invitePeople.actions;

export default invitePeople.reducer;
