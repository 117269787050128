import { combineReducers, configureStore } from "@reduxjs/toolkit";
import userProfileSlice from "@/Redux/userProfile.slice";
import storage from "redux-persist/lib/storage";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from "redux-persist";
import { persistKeys, storeKey } from "@/Utils/config";
import becomeInterviewerSlice from "./Interviewer/becomeInterviewer.slice";
import becomeCompanySlice from "./company/becomeCompany.slice";
import loaderSlice from "./loader/loader.slice";
import interviewerSlice from "./Interviewer/interviewerRole.slice";
import jobSlice from "./job/job.slice";
import jobForMeSlice from "./job/jobForMe.slice";
import canAbilitiesStore from "./canAbility/canAbility.slice";
import appliedApplicantsSlice from "./applicants/applicantsApplied.slice";
import shortListedApplicantsSlice from "./applicants/applicantsShortlisted.slice";
import unSelectedApplicantsSlice from "./applicants/applicantsUnselected.slice";
import addedApplicantsSlice from "./applicants/applicantsAdded.slice";
import jobApplySlice from "./job/jobApply.slice";
import interviewsDefaultSlice from "./interviews/default.slice";
import interviewsInterviewerSlice from "./interviews/interviewer.slice";
import publishedApplicantsSlice from "./applicants/applicantsPublished.slice";
import invitePeople from "./company/invitePeople.slice";
import applicantsCardSlice from "./applicants/applicantsCard.slice";
import scheduleInterviewSlice from "./interviewSchedule/interviewSchedule.slice";
import selectedApplicantsSlice from "./applicants/applicantsSelected.slice";
import chatRoomSlice from "./chat/chatSection/chatRoom.slice";
import chatMessageSlice from "./chat/chatSection/messages.slice";
import chatUsersSlice from "./chat/chatSection/chatUsers.slice";
import chatAuthSlice from "./chat/chatSection/chatAuth.slice";
import fromRecruiterSlice from "./feedback/recruiter/fromRecruiter.slice";

const persistConfig = {
  key: storeKey,
  storage,
  whitelist: [persistKeys.userProfileSlice, persistKeys.applicantsCardSlice],
};

const combinedReducers = persistReducer(
  persistConfig,
  combineReducers({
    userProfile: userProfileSlice,
    becomeInterviewer: becomeInterviewerSlice,
    becomeCompany: becomeCompanySlice,
    loader: loaderSlice,
    interviewer: interviewerSlice,
    jobReducer: jobSlice,
    jobsForMeReducer: jobForMeSlice,
    appliedCandidatesReducer: appliedApplicantsSlice,
    shortListedCandidatesReducer: shortListedApplicantsSlice,
    unSelectedCandidatesReducer: unSelectedApplicantsSlice,
    selectedCandidatesReducer: selectedApplicantsSlice,
    addedApplicantsReducer: addedApplicantsSlice,
    publishedCandidatesReducer: publishedApplicantsSlice,
    jobApplyReducer: jobApplySlice,
    interviewsDefaultReducer: interviewsDefaultSlice,
    interviewsInterviewerReducer: interviewsInterviewerSlice,
    canAbilitiesStore,
    invitePeople,
    applicantsCardReducer: applicantsCardSlice,
    scheduleInterviewReducer: scheduleInterviewSlice,
    chatRoomReducer: chatRoomSlice,
    chatMessageReducer: chatMessageSlice,
    chatUsersReducer: chatUsersSlice,
    chatAuthReducer: chatAuthSlice,
    fromRecruiterReducer: fromRecruiterSlice,
  })
);

export const store = configureStore({
  reducer: combinedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
export const persistedStore = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
