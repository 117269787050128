import {
  deleteCompletedInterviewApi,
  getCompletedInterviewsListsApi,
  getUpcomingInterviewsListApi,
} from "@/Services/apis/interviews/default.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  upcomingInterviewsList: {},
  completedInterviewsList: {},
  upcomingInterviewsDetails: {},
  completedInterviewsDetails: {},
};
export const getUpcomingInterviewsList = createAsyncThunk(
  "getUpcomingInterviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getUpcomingInterviewsListData: JobListType = await getUpcomingInterviewsListApi(value);
      if (getUpcomingInterviewsListData?.code !== 200) return rejectWithValue(getUpcomingInterviewsListData);
      return getUpcomingInterviewsListData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompletedInterviewsList = createAsyncThunk(
  "getCompletedInterviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getCompletedInterviewsListData: JobListType = await getCompletedInterviewsListsApi(value);
      if (getCompletedInterviewsListData?.code !== 200) return rejectWithValue(getCompletedInterviewsListData);
      return getCompletedInterviewsListData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//This interview delete is for delete from Completed Interviews
export const deleteCompletedInteviewsList = createAsyncThunk(
  "deleteCompletedInteviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const completedInteviewsListData: any = await deleteCompletedInterviewApi(value);

      if (completedInteviewsListData?.code !== 200) return rejectWithValue(completedInteviewsListData);
      if (completedInteviewsListData?.code === 200) {
        toast.success(completedInteviewsListData?.message);
        return completedInteviewsListData;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const interviewsDefaultSlice = createSlice({
  name: "interviewsDefaultSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCompletedInterviewsDetails: (state, action) => {
      state.completedInterviewsDetails = action.payload;
    },
    setUpcomingInterviewsDetails: (state, action) => {
      state.upcomingInterviewsDetails = action.payload;
    },
    setInterviewListCardDefault: (state, action) => {
      const updatedList = state.completedInterviewsList?.data?.list?.map(
        (item: any) =>
          item._id === action.payload.updatedData._id
            ? {
                ...item,
                candidateFeedback: action.payload.updatedData?.candidateFeedback,
              } // Update the item with the new data
            : item // Return the same item if it doesn't match
      );

      // Assign the updated list back to the state
      state.completedInterviewsList.data.list = updatedList;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUpcomingInterviewsList.pending, (_state) => {})
      .addCase(getUpcomingInterviewsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.upcomingInterviewsList = {
            code: payload?.code,
            data: {
              list: [...state.upcomingInterviewsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.upcomingInterviewsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getUpcomingInterviewsList.rejected, (_state) => {})
      .addCase(getCompletedInterviewsList.pending, (_state) => {})
      .addCase(getCompletedInterviewsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.completedInterviewsList = {
            code: payload?.code,
            data: {
              list: [...state.completedInterviewsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.completedInterviewsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getCompletedInterviewsList.rejected, (_state) => {})
      .addCase(deleteCompletedInteviewsList.pending, (_state) => {})
      .addCase(deleteCompletedInteviewsList.fulfilled, (_state) => {})
      .addCase(deleteCompletedInteviewsList.rejected, (_state) => {});
  },
});

export const { setCompletedInterviewsDetails, setUpcomingInterviewsDetails, setInterviewListCardDefault } =
  interviewsDefaultSlice.actions;

export default interviewsDefaultSlice.reducer;
