"use client";
import { ComponnetType } from "@/Types/CommonComponents/UiComponentsType";
import React from "react";

const VisitorLayout = ({ children }: ComponnetType) => {
  // States
  // const defaultDark =window window.matchMedia('(prefers-color-scheme: dark)').matches
  // const [theme, setTheme] = React.useState<string>("light");

  // Functions
  // const handleTheme = () => {
  //   setTheme(theme === "light" ? "dark" : "light");
  // };

  // useEffects
  // React.useEffect(() => {
  //   document.documentElement.setAttribute("color-scheme", theme);
  // }, [theme]);
  return <div>{children}</div>;
};

export default VisitorLayout;
