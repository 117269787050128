// Verify the referral code
/* eslint-disable @typescript-eslint/no-explicit-any */

import { idTokenFromLocalStorage, idTokenFromSessionStorage } from "@/Utils/config";
import axiosMethods from "../https/https";

//Send otp
export const sendOtpApi = async (values: any) => {
  const sendOtp = await axiosMethods.post(`/company`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return sendOtp;
};

//Verify business email
export const verifyBusinessEmailConfirmationApi = async (values: any) => {
  const confirmation = await axiosMethods.post(`/company`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return confirmation;
};

//Signup part
export const companySignupApi = async (values: any) => {
  const companyData = await axiosMethods.post(`/company`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return companyData;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
