import { createSlice } from "@reduxjs/toolkit";

// Define the initial state using that type

/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  toInterviewer: "",
  toJobSeeker: "",
};

export const fromRecruiterSlice = createSlice({
  name: "fromRecruiterSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setFeedbacktoInterviewer: (state, action) => {
      state.toInterviewer = action.payload;
    },
    setFeedbacktoJobSeeker: (state, action) => {
      state.toJobSeeker = action.payload;
    },
  },

  extraReducers: () => {},
});

export const { setFeedbacktoInterviewer, setFeedbacktoJobSeeker } = fromRecruiterSlice.actions;

export default fromRecruiterSlice.reducer;
