import { companySignupApi, sendOtpApi, verifyBusinessEmailConfirmationApi } from "@/Services/apis/becomeCompany.api";

import { getS3SignUrl } from "@/Services/apis/common.api";
import { getLocalstorageData } from "@/Utils/CommonFuncs";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getUser } from "../userProfile.slice";
import i18n from "@/Utils/i18n";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  compnayLogo: {},
};
/* eslint-enable @typescript-eslint/no-explicit-any */
//All file uploads
/* eslint-disable @typescript-eslint/no-explicit-any */
export const fileUploadCompany = createAsyncThunk("fileUploadCompany", async (values: any, { rejectWithValue }) => {
  const file = values.blobFile;
  values.role = getLocalstorageData("currentDashboardRole");
  try {
    const data: any = await getS3SignUrl(values);

    if (data?.code !== 200) return rejectWithValue(data);
    if (data?.code === 200) {
      if (values?.type === "compnayLogo") {
        values?.dispatch(
          setCompanyLogo({
            file,
            type: values?.dataFiles?.ContentType,
            name: values?.dataFiles?.fileName,
            link: data?.uploadImageUrl,
          })
        );
        values?.becomeCompanyFormik?.setFieldValue("compnayLogo", "done");
      }
    }
    return { data };
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
/* eslint-enable @typescript-eslint/no-explicit-any */
//Verify business email address
/* eslint-disable @typescript-eslint/no-explicit-any */

export const verifyBusinessEmail = createAsyncThunk("verifyBusinessEmail", async (values: any, { rejectWithValue }) => {
  try {
    const verifyBusinessEmailConfirmation: any = await verifyBusinessEmailConfirmationApi(values);
    if (verifyBusinessEmailConfirmation?.code !== 200) return rejectWithValue(verifyBusinessEmailConfirmation);
    if (verifyBusinessEmailConfirmation?.code === 200) {
      values?.setverifiedOtp(true);
      toast.success(i18n.t("cms_email_verified"));
    }
    return verifyBusinessEmailConfirmation;
  } catch (error: any) {
    toast.error(i18n.t("cms_business_email_verified"));

    return rejectWithValue(error.response.data);
  }
});

//Verify business email address
/* eslint-disable @typescript-eslint/no-explicit-any */

export const sendOtp = createAsyncThunk("sendOtp", async (values: any, { rejectWithValue }) => {
  try {
    const sendOtpFunc: any = await sendOtpApi(values);
    if (sendOtpFunc?.code !== 200) return rejectWithValue(sendOtpFunc);
    if (sendOtpFunc?.code === 200) {
      const email = values?.bodyData?.companyEmail;
      toast.success(i18n.t("cms_otp_sent_to_email", { email }));
    }
    return sendOtpFunc;
  } catch (error: any) {
    toast.success("Your verification code is send your company business email");
    return rejectWithValue(error.response.data);
  }
});
// Company Sign up
export const companySignup = createAsyncThunk("companySignup", async (values: any, { rejectWithValue }) => {
  try {
    const companySignupdetails: any = await companySignupApi(values);
    if (companySignupdetails?.code !== 201) return rejectWithValue(companySignupdetails);
    if (companySignupdetails?.code === 201) {
      values.dispatch(getUser({ resource: "get-user", profileRole: values.role }));
      values?.router && values?.router?.replace("/feeds");
    }
    return companySignupdetails;
  } catch (error: any) {
    // values?.router && values?.router?.replace("/feeds");

    return rejectWithValue(error.response.data);
  }
});

export const becomeCompanySlice = createSlice({
  name: "becomeCompanySlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCompanyLogo: (state, action) => {
      state.compnayLogo = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder;
  },
});

export const { setCompanyLogo } = becomeCompanySlice.actions;

export default becomeCompanySlice.reducer;
