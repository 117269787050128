import {
  getCompletedInterviewerInterviewsListsApi,
  getInterviewDetailsApi,
  getNewInterviewerInterviewsListApi,
  getPendingInterviewerInterviewsListsApi,
  getUpcomingInterviewerInterviewsListApi,
  rejectNewInterviewApi,
} from "@/Services/apis/interviews/interviewer.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  upcomingInterviewerInterviewsList: {},
  completedInterviewerInterviewsList: {},
  newInterviewerInterviewsList: {},
  pendingInterviewerInterviewsList: {},
  upcomingInterviewerInterviewsDetails: {},
  completedInterviewerInterviewsDetails: {},
  newInterviewerInterviewsDetails: {},
  pendingInterviewerInterviewsDetails: {},
  content: "",
};
export const getUpcomingInterviewerInterviewsList = createAsyncThunk(
  "getUpcomingInterviewerInterviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getUpcomingInterviewerInterviewsListData: JobListType =
        await getUpcomingInterviewerInterviewsListApi(value);
      if (getUpcomingInterviewerInterviewsListData?.code !== 200)
        return rejectWithValue(getUpcomingInterviewerInterviewsListData);
      return getUpcomingInterviewerInterviewsListData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCompletedInterviewerInterviewsList = createAsyncThunk(
  "getCompletedInterviewerInterviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getCompletedInterviewerInterviewsData: JobListType = await getCompletedInterviewerInterviewsListsApi(value);
      if (getCompletedInterviewerInterviewsData?.code !== 200)
        return rejectWithValue(getCompletedInterviewerInterviewsData);
      return getCompletedInterviewerInterviewsData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getNewInterviewerInterviewsList = createAsyncThunk(
  "getNewInterviewerInterviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getNewInterviewerInterviewsListData: JobListType = await getNewInterviewerInterviewsListApi(value);
      if (getNewInterviewerInterviewsListData?.code !== 200)
        return rejectWithValue(getNewInterviewerInterviewsListData);
      return getNewInterviewerInterviewsListData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getPendingInterviewerInterviewsList = createAsyncThunk(
  "getPendingInterviewerInterviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getPendingInterviewerInterviewsListData: JobListType = await getPendingInterviewerInterviewsListsApi(value);
      if (getPendingInterviewerInterviewsListData?.code !== 200)
        return rejectWithValue(getPendingInterviewerInterviewsListData);
      return getPendingInterviewerInterviewsListData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//This interview reject is for reject from New Interviews
export const rejectNewInteviewsList = createAsyncThunk(
  "rejectNewInteviewsList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const newInteviewsListData: any = await rejectNewInterviewApi(value);

      if (newInteviewsListData?.code !== 200) return rejectWithValue(newInteviewsListData);
      if (newInteviewsListData?.code === 200) {
        toast.success(newInteviewsListData?.message);
        return newInteviewsListData;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterviewDetailsFromInterviewId = createAsyncThunk(
  "getInterviewDetailsFromInterviewId",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getInterviewDetailsFromInterviewIdData: any = await getInterviewDetailsApi(value);
      if (getInterviewDetailsFromInterviewIdData?.code !== 200)
        return rejectWithValue(getInterviewDetailsFromInterviewIdData);
      return getInterviewDetailsFromInterviewIdData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getInterviewDetailsGlobally = createAsyncThunk(
  "getInterviewDetailsGlobally",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getInterviewDetailsFromInterviewData: any = await getInterviewDetailsApi(value);
      if (getInterviewDetailsFromInterviewData?.code !== 200)
        return rejectWithValue(getInterviewDetailsFromInterviewData);
      return getInterviewDetailsFromInterviewData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const interviewsInterviewerSlice = createSlice({
  name: "interviewsInterviewerSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCompletedInterviewerInterviewsDetails: (state, action) => {
      state.completedInterviewerInterviewsDetails = action.payload;
    },
    setUpcomingInterviewerInterviewsDetails: (state, action) => {
      state.upcomingInterviewerInterviewsDetails = action.payload;
    },
    setNewInterviewerInterviewsDetails: (state, action) => {
      state.newInterviewerInterviewsDetails = action.payload;
    },
    setPendingInterviewerInterviewsDetails: (state, action) => {
      state.pendingInterviewerInterviewsDetails = action.payload;
    },
    setInterviewListCard: (state, action) => {
      const updatedList = state.upcomingInterviewerInterviewsList?.data?.list?.map(
        (item: any) =>
          item._id === action.payload.updatedData._id
            ? {
                ...item,
                isReadyToStart: action.payload.updatedData?.isReadyToStart,
              } // Update the item with the new data
            : item // Return the same item if it doesn't match
      );

      // Assign the updated list back to the state
      state.upcomingInterviewerInterviewsList.data.list = updatedList;
    },

    setInterviewListCardForStatus: (state, action) => {
      const updatedList = state.upcomingInterviewerInterviewsList?.data?.list?.map(
        (item: any) =>
          item._id === action.payload.updatedData._id
            ? { ...item, status: action.payload.updatedData?.status } // Update the item with the new data
            : item // Return the same item if it doesn't match
      );

      // Assign the updated list back to the state
      state.upcomingInterviewerInterviewsList.data.list = updatedList;
    },
    setEditorContent: (state, action: PayloadAction<string>) => {
      state.content = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getUpcomingInterviewerInterviewsList.pending, (_state) => {})
      .addCase(getUpcomingInterviewerInterviewsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.upcomingInterviewerInterviewsList = {
            code: payload?.code,
            data: {
              list: [...state.upcomingInterviewerInterviewsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.upcomingInterviewerInterviewsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getUpcomingInterviewerInterviewsList.rejected, (_state) => {})
      .addCase(getCompletedInterviewerInterviewsList.pending, (_state) => {})
      .addCase(getCompletedInterviewerInterviewsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.completedInterviewerInterviewsList = {
            code: payload?.code,
            data: {
              list: [...state.completedInterviewerInterviewsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.completedInterviewerInterviewsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getCompletedInterviewerInterviewsList.rejected, (_state) => {})
      .addCase(getNewInterviewerInterviewsList.pending, (_state) => {})
      .addCase(getNewInterviewerInterviewsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.newInterviewerInterviewsList = {
            code: payload?.code,
            data: {
              list: [...state.newInterviewerInterviewsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.newInterviewerInterviewsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getNewInterviewerInterviewsList.rejected, (_state) => {})
      .addCase(getPendingInterviewerInterviewsList.pending, (_state) => {})
      .addCase(getPendingInterviewerInterviewsList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.pendingInterviewerInterviewsList = {
            code: payload?.code,
            data: {
              list: [...state.pendingInterviewerInterviewsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.pendingInterviewerInterviewsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getPendingInterviewerInterviewsList.rejected, (_state) => {})
      .addCase(rejectNewInteviewsList.pending, (_state) => {})
      .addCase(rejectNewInteviewsList.fulfilled, (_state) => {})
      .addCase(rejectNewInteviewsList.rejected, (_state) => {})
      .addCase(getInterviewDetailsFromInterviewId.pending, (_state) => {})
      .addCase(getInterviewDetailsFromInterviewId.fulfilled, () => {
        /* eslint-disable no-unsafe-optional-chaining */
        // state.upcomingInterviewerInterviewsList?.map((item:any) =>
        //     item._id === payload?.data?._id ? {...payload?.data } : item
        //   )
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getInterviewDetailsFromInterviewId.rejected, (_state) => {});
  },
});

export const {
  setCompletedInterviewerInterviewsDetails,
  setUpcomingInterviewerInterviewsDetails,
  setNewInterviewerInterviewsDetails,
  setPendingInterviewerInterviewsDetails,
  setInterviewListCard,
  setInterviewListCardForStatus,
  setEditorContent,
} = interviewsInterviewerSlice.actions;

export default interviewsInterviewerSlice.reducer;
