import { RootState } from "@/Redux/store";
import { authincateUser } from "@/Utils/constants/EmitFunctions/emitFunctions";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";

interface ConnectionStateProps {
  webSocket: WebSocket;
}

export default function ConnectionState({ webSocket: initialWebSocket }: ConnectionStateProps) {
  const [webSocket, setWebSocket] = useState(initialWebSocket);
  const [connectionText, setConnectionText] = useState("Connecting...");
  const { user, selectedDashboardData } = useSelector((state: RootState) => state.userProfile);

  const reconnectDelay = 5000; // Delay for reconnection attempts in milliseconds

  const setupWebSocket = useCallback(() => {
    // Create a new WebSocket connection
    const newWebSocket = new WebSocket(initialWebSocket?.url);

    newWebSocket.onopen = () => {
      setConnectionText("Connection Established.");

      // Check if WebSocket is open before sending the message
      if (newWebSocket?.readyState === WebSocket.OPEN) {
        const dataToSend = {
          message: { appUserId: user?._id, userRoleProfileId: selectedDashboardData?._id },
        };
        authincateUser(dataToSend);
      } else {
        console.error("WebSocket is not open, unable to send message.");
      }
    };

    newWebSocket.onclose = (event) => {
      setConnectionText(`Disconnected: ${event.reason}`);
      setTimeout(setupWebSocket, reconnectDelay); // Attempt to reconnect after delay
    };

    newWebSocket.onerror = (event) => {
      setConnectionText("Connection Error!");
      console.error("WebSocket error observed:", event);
    };

    // Update WebSocket state
    setWebSocket(newWebSocket);
  }, [initialWebSocket?.url, user?._id, selectedDashboardData?._id]);

  useEffect(() => {
    setupWebSocket();

    return () => {
      webSocket?.close(); // Cleanup on component unmount
    };
  }, [setupWebSocket]);

  return (
    <></>
    // <div style={{ position: "sticky", zIndex: 3, top: 0 }}>{connectionText}</div>
  );
}
