"use client";

import { OAuthConfig } from "@/Types/CognitoTypes";
import { Amplify, type ResourcesConfig } from "aws-amplify";

const oauthConfig: OAuthConfig = {
  domain: `${process.env.NEXT_PUBLIC_USERPOOL_DOMAIN}`,
  scopes: ["email", "openid", "profile", "phone", "aws.cognito.signin.user.admin"],
  responseType: "code",
  redirectSignIn: [
    `${typeof window !== "undefined" && window.location.origin}/${process.env.NEXT_PUBLIC_REDIRECT_URL}`,
  ],
  redirectSignOut: [`${typeof window !== "undefined" && window.location.origin}`],
};

export const authConfig: ResourcesConfig["Auth"] = {
  Cognito: {
    // region: "us-east-1",
    userPoolId: String(process.env.NEXT_PUBLIC_USER_POOL_ID),
    userPoolClientId: String(process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID),
    loginWith: {
      oauth: {
        ...oauthConfig,
      },
    },
  },
};

Amplify.configure(
  {
    Auth: authConfig,
  },
  { ssr: true }
);

export default function ConfigureAmplifyClientSide() {
  return null;
}
