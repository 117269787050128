// Verify the referral code
/* eslint-disable @typescript-eslint/no-explicit-any */

import { idTokenFromLocalStorage, idTokenFromSessionStorage } from "@/Utils/config";
import axiosMethods from "../https/https";

export const verifyReferralCodeConfirmationApi = async (values: any) => {
  const update = await axiosMethods.post(`/interviewer`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return update;
};

export const interviewerSignupApi = async (values: any) => {
  const interviewerData = await axiosMethods.post(`/interviewer`, values.bodyData, {
    headers: {
      Authorization: `${idTokenFromLocalStorage || idTokenFromSessionStorage}`,
      Resource: values?.resource,
      ProfileRole: values?.role,
    },
  });
  return interviewerData;
};
/* eslint-enable @typescript-eslint/no-explicit-any */
