"use client";

import React, { useState } from "react";

import Image from "next/image";
import src from "@/../../public/assets/src.svg";
import bell from "@/../../public/assets/bell.svg";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";

import IconButton from "@mui/material/IconButton";
// import menuClose from "@/../../public/assets/menu-close.svg";

import HamMenuIcon from "@mui/icons-material/Menu";
import { NavbarType } from "@/Types/CommonComponents/UiComponentsType";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/Redux/store";
import { UserRoleType } from "@/Types/PropsPassTypes/PropsTypes";
import { switchRole } from "@/Redux/userProfile.slice";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { firstLetterCapitalFunc } from "@/Utils/CommonFuncs";
import { useRouter } from "next/navigation";
import UserIcon from "@/../../public/assets/user.png";
import { navHeadTitle } from "@/Utils/constants/i18Lang/RouteHeader";
import { usePathname } from "next/navigation";

const CustomTopNav = ({
  setOpenHamburger,
  openHamburger,
  // setExpanded,
}: NavbarType) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
  const dispatch = useDispatch<ThunkAppDispatch>();
  const router = useRouter();
  const pathname = usePathname();

  const { user, selectedDashboardData } = useSelector((state: RootState) => state.userProfile);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [defaultProfileImage, setDefaultProfileImage] = useState(user?.profileImage);
  const open = Boolean(anchorEl);
  const userAvailableRoles = user?.roleProfiles?.map((item: UserRoleType) => {
    return item?.profileType === "Default"
      ? {
          ...item,
          profileType: "Default",
        }
      : item;
  });
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHamburger = () => {
    setOpenHamburger(!openHamburger);
  };

  // const handleSideNav = () => {
  //   if (document.documentElement.getAttribute("sidenav-status") === "open") {
  //     setExpanded(false);
  //     document.documentElement.setAttribute("sidenav-status", "close");
  //   } else if (
  //     document.documentElement.getAttribute("sidenav-status") === "close"
  //   ) {
  //     document.documentElement.setAttribute("sidenav-status", "open");
  //   }
  // };

  // Change Role Api integration
  const handleChangeRole = (roleProfile: UserRoleType) => {
    const bodyData = {
      roleProfileId: roleProfile?._id,
    };
    const value = {
      resource: "select-dashboard",
      bodyData,
      dispatch,
      updatedRole: roleProfile?.profileType,
      router,
    };
    dispatch(switchRole(value));
    handleClose();
  };

  React.useEffect(() => {
    setDefaultProfileImage(user?.profileImage);
  }, [user?.profileImage]);

  React.useEffect(() => {
    document.documentElement.setAttribute("sidenav-status", "open");
  }, []);
  const gotoPage = () => {
    if (navHeadTitle(pathname)?.redirectRoute) {
      router.push(navHeadTitle(pathname)?.redirectRoute as string);
    }
  };
  return (
    <div className="dash-top-nav grey-border">
      {/* <div className="menu-close-icon" onClick={handleSideNav}>
        <IconButton sx={{ padding: 0 }}>
          <Image alt="" src={menuClose} />
        </IconButton>
      </div> */}
      {/* <span className="backbtn">
      <IoMdArrowRoundBack />
      </span> */}
      <h1
        className={`fs-36 ${!!navHeadTitle(pathname)?.redirectRoute && "pointer"}`}
        onClick={() => !!navHeadTitle(pathname)?.redirectRoute && gotoPage()}
      >
        {navHeadTitle(pathname)?.title}
      </h1>

      <div className="src-box">
        <form>
          <button>
            <Image alt="src" src={src} className="srcIcon" priority />
          </button>
          <input type="text" placeholder="Search something...." className="search-inp" />
        </form>
      </div>

      <div className="topnav-right">
        <Box sx={{ color: "action.active" }} className="notification">
          <Badge color="secondary" variant="dot">
            <Image alt="bell" src={bell} className="notifi" priority />
          </Badge>
        </Box>
        <Button
          className="user-menu-btn"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <div className="user-menu">
            {/* <Avatar


              className="user"
              alt="Sean Doe"
              src="/assets/user.png"
              sx={{ width: 50, height: 50 }}
            /> */}
            <Image
              alt="profile-img"
              height={50}
              width={50}
              src={defaultProfileImage ? defaultProfileImage : UserIcon.src}
              onError={() => {
                setDefaultProfileImage(UserIcon.src);
              }}
              className="post-img"
              blurDataURL={"/assets/blurdataurl.gif"}
              placeholder="blur"
              loading="lazy"
            />
            <span>
              {user?.name}
              <br />
              <span className={`current-role-viwer`}>{selectedDashboardData?.profileType}</span>
            </span>
          </div>
        </Button>
        <Menu
          className="custom-menu"
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          {userAvailableRoles?.map((item: UserRoleType, index: number) => {
            return (
              <MenuItem
                key={index}
                onClick={() => !(item?._id === selectedDashboardData?._id) && handleChangeRole(item)}
              >
                {item?.referenceId?.companyName
                  ? firstLetterCapitalFunc(item?.referenceId?.companyName)
                  : item?.profileType}{" "}
                Dashboard {item?._id === selectedDashboardData?._id && <span className={`active-icon`}></span>}
              </MenuItem>
            );
          })}
        </Menu>
        <IconButton sx={{ padding: 0 }} className="hammenuBtn" onClick={handleHamburger}>
          <HamMenuIcon />
        </IconButton>
      </div>
      {/* <FullScreenLoader /> */}
    </div>
  );
};

export default CustomTopNav;
