import {
  deleteJobApi,
  deleteSavedJobApi,
  getFilteredJobListForMeApi,
  getJobListForMeApi,
  getJobListForMeAppliedApi,
  savedJobApi,
} from "@/Services/apis/job.api";
import { JobListType } from "@/Types/Jobs/JobsType";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

// Define the initial state using that type
/* eslint-disable @typescript-eslint/no-explicit-any */
const initialState: any = {
  newJobsList: {},
  filteredJobList: {},
  appliedJobsList: {},
  savedJobsList: {},
  newJobDetails: {},
  savedJobDetails: {},
  appliedJobDetails: {},
};
export const getNewJobList = createAsyncThunk("getNewJobList", async ({ value }: any, { rejectWithValue }) => {
  try {
    const getNewJobListData: JobListType = await getJobListForMeApi(value);
    if (getNewJobListData?.code !== 200) return rejectWithValue(getNewJobListData);
    return getNewJobListData;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const getFileteredJobList = createAsyncThunk(
  "getFileteredJobList",
  async ({ value }: any, { rejectWithValue }) => {
    try {
      const getFilteredJobListData: JobListType = await getFilteredJobListForMeApi(value);
      if (getFilteredJobListData?.code !== 200) return rejectWithValue(getFilteredJobListData);
      return getFilteredJobListData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAppliedJobList = createAsyncThunk("getAppliedJobList", async ({ value }: any, { rejectWithValue }) => {
  try {
    const getAplliedJobListData: JobListType = await getJobListForMeAppliedApi(value);
    if (getAplliedJobListData?.code !== 200) return rejectWithValue(getAplliedJobListData);
    return getAplliedJobListData;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const getSavedJobList = createAsyncThunk("getSavedJobList", async ({ value }: any, { rejectWithValue }) => {
  try {
    const getSavedJobListData: any = await getJobListForMeApi(value); //eslint-disable-line
    if (getSavedJobListData?.code !== 200) return rejectWithValue(getSavedJobListData);
    return getSavedJobListData;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const saveJob = createAsyncThunk("saveJob", async ({ value }: any, { rejectWithValue }) => {
  try {
    const saveJobData: any = await savedJobApi(value);

    if (saveJobData?.code !== 200) return rejectWithValue(saveJobData);
    if (saveJobData?.code === 200) {
      toast.success(saveJobData?.message);
      return saveJobData;
    }
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const deleteJob = createAsyncThunk("deleteJob", async ({ value }: any, { rejectWithValue }) => {
  try {
    const deleteJobData: any = await deleteJobApi(value);

    if (deleteJobData?.code !== 200) return rejectWithValue(deleteJobData);
    if (deleteJobData?.code === 200) {
      toast.success(deleteJobData?.message);
      value?.getNewJobs(value?.page);
      return deleteJobData;
    }
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteSavedJob = createAsyncThunk("deleteSavedJob", async ({ value }: any, { rejectWithValue }) => {
  try {
    const deleteSavedJobData: any = await deleteSavedJobApi(value);

    if (deleteSavedJobData?.code !== 200) return rejectWithValue(deleteSavedJobData);
    if (deleteSavedJobData?.code === 200) {
      toast.success(deleteSavedJobData?.message);
      return deleteSavedJobData;
    }
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const jobForMeSlice = createSlice({
  name: "jobForMeSlice",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setNewJobDetails: (state, action) => {
      state.newJobDetails = action.payload;
    },
    setSavedJobDetails: (state, action) => {
      state.savedJobDetails = action.payload;
    },
    setAppliedJobDetails: (state, action) => {
      state.appliedJobDetails = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getNewJobList.pending, (_state) => {})
      .addCase(getNewJobList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.newJobsList = {
            code: payload?.code,
            data: {
              list: [...state.newJobsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.newJobsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getNewJobList.rejected, (_state) => {})
      .addCase(getFileteredJobList.pending, (_state) => {})
      .addCase(getFileteredJobList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.filteredJobList = {
            code: payload?.code,
            data: {
              list: [...state.filteredJobList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.filteredJobList = payload;
        }

        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getFileteredJobList.rejected, (_state) => {})

      .addCase(getAppliedJobList.pending, (_state) => {})
      .addCase(getAppliedJobList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.appliedJobsList = {
            code: payload?.code,
            data: {
              list: [...state.appliedJobsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.appliedJobsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getAppliedJobList.rejected, (_state) => {})

      .addCase(getSavedJobList.pending, (_state) => {})
      .addCase(getSavedJobList.fulfilled, (state, { payload }) => {
        /* eslint-disable no-unsafe-optional-chaining */
        if (payload?.data?.pagination?.page > 1) {
          state.savedJobsList = {
            code: payload?.code,
            data: {
              list: [...state.savedJobsList?.data?.list, ...payload?.data?.list],
              pagination: payload?.data?.pagination,
            },
          };
        } else {
          state.savedJobsList = payload;
        }
        /* eslint-enable no-unsafe-optional-chaining */
      })
      .addCase(getSavedJobList.rejected, (_state) => {})
      .addCase(saveJob.pending, (_state) => {})
      .addCase(saveJob.fulfilled, (_state) => {})
      .addCase(saveJob.rejected, (_state) => {})
      .addCase(deleteJob.pending, (_state) => {})
      .addCase(deleteJob.fulfilled, (_state) => {})
      .addCase(deleteJob.rejected, (_state) => {})
      .addCase(deleteSavedJob.pending, (_state) => {})
      .addCase(deleteSavedJob.fulfilled, (_state) => {})
      .addCase(deleteSavedJob.rejected, (_state) => {});
  },
});

export const { setNewJobDetails, setSavedJobDetails, setAppliedJobDetails } = jobForMeSlice.actions;

export default jobForMeSlice.reducer;
