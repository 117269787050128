export const authRoutes = [
  "/",
  "/auth/login",
  "/auth/forgot-password",
  "/auth/reset-password",
  "/auth/signup",
  "/auth/verification",
  "/auth/social-verifier",
];
export const publicRoutes = [
  "/cookies",
  "/faq",
  "/privacy-policy",
  "/terms-conditions",
  "/terms-service",
  "/user-agreement",
  "/design/interviewvideo",
  "/design/fullvideo",
  "/interview_record",
  "/session_closed",
];
