import axiosMethods from "@/Services/https/https";
import catchAsync from "@/Utils/catchAsync";
//This is for when user schedule the interview
export const postScheduleInterviewApi = async (values: any) => {
  //eslint-disable-line
  const postScheduleInterviewData: any = await axiosMethods.post(
    //eslint-disable-line
    `/schedule`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return postScheduleInterviewData;
};
//This is for getting list of schedules Interviews List

export const getScheduleIntervieListApi = async (values: any) => {
  //eslint-disable-line
  const getScheduleIntervieListData: any = await axiosMethods.post(
    //eslint-disable-line
    `/schedule`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return getScheduleIntervieListData;
};

//This is for when user accept/confirm schedule interview

export const acceptScheduledInterview = catchAsync(
  async (values: { bodyData: { scheduleId: string }; updatedRole: string }) => {
    //eslint-disable-line
    const data: any = await axiosMethods.post(
      //eslint-disable-line
      `/schedule`,
      values.bodyData,
      {
        headers: {
          Resource: "confirm-schedule-interview",
          ProfileRole: values?.updatedRole,
        },
      }
    );

    return data;
  }
);

//This is for getting details of the scheduled interview

export const scheduledInterviewdetailsApi = catchAsync(
  async (values: { bodyData: { scheduleId: string }; updatedRole: string }) => {
    //eslint-disable-line
    const data: any = await axiosMethods.post(
      //eslint-disable-line
      `/schedule`,
      values.bodyData,
      {
        headers: {
          Resource: "schedule-details",
          ProfileRole: values?.updatedRole,
        },
      }
    );

    return data;
  }
);
//This is for when user cancel schedule interview
export const cancelScheduledInterview = catchAsync(
  async (values: { bodyData: { scheduleId: string }; role: string }) => {
    //eslint-disable-line
    const data: any = await axiosMethods.post(
      //eslint-disable-line
      `/schedule`,
      values.bodyData,
      {
        headers: {
          Resource: "cancel-schedule-interview",
          ProfileRole: values?.role,
        },
      }
    );

    return data;
  }
);
//This is for when interviewer starts recording
export const interviewRecordStartApi = async (values: any) => {
  //eslint-disable-line
  const interviewRecordStartData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return interviewRecordStartData;
};

//This is for when user cancel schedule interview
export const stopInterviewSessionApi = async (values: any) => {
  //eslint-disable-line
  const data: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );

  return data;
};

//This is for when user upload the file in interview session
export const uploadFileForInterviewSession = catchAsync(
  async (values: { bodyData: { scheduleId: string }; role: string }) => {
    //eslint-disable-line
    const data: any = await axiosMethods.post(
      //eslint-disable-line
      `/interview`,
      values.bodyData,
      {
        headers: {
          Resource: "update-session-file",
          ProfileRole: values?.role,
        },
      }
    );

    return data;
  }
);

//Complete Interview from interviewer

//Applied lists>>>Accept for published interview
export const completeInterviewFromInterviewer = async (values: { bodyData: { interviewId: string }; role: string }) => {
  //eslint-disable-line
  const data: { code: number; message: string } = await axiosMethods.post(`/interview`, values.bodyData, {
    headers: {
      Resource: "interview-complete-by-interviewer",
      ProfileRole: values?.role,
    },
  });

  return data;
};

//This is for start interview initiation by interviewer
//This is for getting interviewDetails
export const startInterviewApi = async (values: any) => {
  //eslint-disable-line
  const startInterviewData: any = await axiosMethods.post(
    //eslint-disable-line
    `/interview`,
    values.bodyData,
    {
      headers: {
        Resource: values?.resource,
        ProfileRole: values?.role,
      },
    }
  );
  return startInterviewData;
};
